import UserProvider from './context/UserProvider';
import React from "react";

function App() {
  return (
    <div>
      <UserProvider />
    </div>
  );
}

export default App;
